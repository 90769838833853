/*
 * @Descripttion: 文件API集合
 * @version: 
 * @Author: luoxi
 * @Date: 2019年11月30日15:52:24
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月19日15:46:26
 */

import request from '@/plugins/axios'

/**
 * 文件及文件夹列表,对应全部文件
 * 
 * @name: 名称
 * @pageIndex: 当前页
 * @pageNums: 每页显示数量
 * @sortType: 排序类型（1：名称 2：日期 3：大小）
 * @desc: 排序类型 asc：正序，desc:倒序
 * @fileDownloadUrl: 文件下载地址
 * @dirId: 父级id
 * size=100x100 自定义图片修改参数 加载地址后面，对应是宽x高
 **/
export function allList(data){
  return request({
      url: '/file/list/getPage',
      method: 'post',
      data: data
  })
}

/**
 * 对应图片、文档、视频、音乐、其他的列表接口
 * 
 * @fileType: 文件类型(1：图片 2：视频 3：音频 4：文档 5：其他)
 * @name: 名称
 * @pageIndex: 当前页
 * @pageNums: 每页显示数量
 * @sortType: 排序类型（1：名称 2：日期 3：大小）
 * @desc: 排序类型 asc：正序，desc:倒序
 **/
export function typeList(data){
  return request({
      url: '/file/list/type',
      method: 'post',
      data: data
  })
}


/**
 * 文件夹新增
 * 
 * @name: 名称
 * parentId: 父级id
 **/
export function dirAdd(data){
  return request({
      url: '/file/dir/add',
      method: 'post',
      data: data
  })
}

/**
 * 文件夹名称修改
 * 
 * @name: 名称
 * @id: 文件夹ID
 * @parentId: 父级id
 **/
export function dirUpdateName(data){
  return request({
      url: '/file/dir/updateName',
      method: 'post',
      data: data
  })
}

/**
 * 文件名称修改
 * 
 * @name: 名称
 * @id: 文件ID
 **/
export function fileUpdateName(data){
  return request({
      url: '/file/updateName',
      method: 'post',
      data: data
  })
}

/**
 * 文件移至回收站
 * 
 * @ids: 文件ID集合,字符串,多个以逗号隔开,单个不用逗号
 **/
export function fileRemove(data){
  return request({
      url: '/file/remove',
      method: 'post',
      data: data
  })
}

/**
 * 文件夹移至回收站
 * 
 * @ids: 文件ID集合,字符串,多个以逗号隔开,单个不用逗号
 **/
export function dirRemove(data){
  return request({
      url: '/file/dir/remove',
      method: 'post',
      data: data
  })
}
