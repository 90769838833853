import axios from 'axios'
import util from '@/plugins/utils'
import { Message } from 'element-ui'

// 创建服务
const service = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Access-Control-Max-Age'] = 86400
    if (util.cookies.get('token')) {
      config.headers['token'] = util.cookies.get('token')
      if (process.env.NODE_ENV === 'development') {
        config.headers['x-forwarded-for'] = '218.201.197.186'
      }
      
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if(res.code === 200){
      return response.data;
    }else{
      if (res.code === 0 ) {
        // 一般code为0代表需要重新登录，做相应的逻辑判断即可
      }else{
        Message({
          showClose: true,
          message: res.msg || 'error',
          type: 'warning',
          duration: 5 * 1000
        })
        return Promise.reject(res.msg || 'error')
      }
    }
  },
  error => {
    Message({
      showClose: true,
      message: "系统连接失败，请稍侯再试",
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service